<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form">
      <a-row>
        <a-col>
          <a-form-model-item label="用戶Id" prop="id">
            {{ form.id }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="用戶名/手機號" prop="mobile">
            <!-- <span>{{ form.mobile }}</span> -->
            <a-input v-model="form.mobile" disabled />
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="刷手號" prop="userNum">
            <a-input v-model="form.userNum" disabled />
          </a-form-model-item>
        </a-col>

        <a-col>
          <a-form-model-item label="頭像" prop="avatar">
            <img style="width: 100px;height: 100px;" preview="頭像"
            :src="form.avatar">
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="昵稱" prop="nickname">
            <!-- <span>{{ form.nickname }}</span> -->
            <a-input v-model="form.nickname" disabled />
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="性别" prop="sex">
            <span v-if="form.sex == 0">保密</span>
            <span v-if="form.sex == 1">男</span>
            <span v-if="form.sex == 2">女</span>
          </a-form-model-item>

        </a-col>
        <a-col>
        <a-form-model-item label="生日" prop="birthday">
          <a-input v-model="form.birthday" disabled />
        <!-- <span v-if="form.birthday">
          {{ form.birthday }}
        </span>
        <span v-else>-</span> -->
      </a-form-model-item>
    </a-col>
        <!-- <a-col :span="24" :pull="3">
          <a-form-model-item label="備註">
            <a-textarea placeholder="textarea with clear icon" allow-clear v-model="form.userSign" />
          </a-form-model-item>
        </a-col> -->
        <a-col>
        <a-form-model-item label="用戶個性簽名" prop="userSign" >
        <!-- <span>{{ form.userSign }}</span> -->
        <a-textarea disabled allow-clear v-model="form.userSign" />
      </a-form-model-item>
    </a-col>
      <a-col>
      <a-form-model-item label="用戶狀態" prop="status" >
        <span v-if="form.status == 1">正常</span>
        <span v-if="form.status == 0">禁用</span>
        <span v-if="form.status == 2">停用</span>
      </a-form-model-item>
    </a-col>

        <a-col>
          <a-form-model-item label="所在地" prop="detailedAddress">
            <!-- <span>{{ form.nickname }}</span> -->
            <a-input v-model="form.detailedAddress" disabled placeholder="-"/>
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="背景圖" prop="background">
            <!-- <span>{{ form.nickname }}</span> -->
            <img style="width: 100px;height: 100px;" preview="背景圖"
            :src="form.background">
          </a-form-model-item>
        </a-col>

        <a-col>
          <a-form-model-item label="聯系我們" prop="aboutTel">
            <!-- <span>{{ form.nickname }}</span> -->
            <a-input v-model="form.aboutTel" disabled placeholder="-"/>
          </a-form-model-item>
        </a-col>

        <a-col>
          <a-form-model-item label="獲贊數" prop="praiseNum">
            <!-- <span>{{ form.nickname }}</span> -->
            <a-input v-model="form.praiseNum" disabled placeholder="0"/>
          </a-form-model-item>
        </a-col>

        <a-col>
          <a-form-model-item label="關註人數" prop="followerNum">
            <!-- <span>{{ form.nickname }}</span> -->
            <a-input v-model="form.followerNum" disabled placeholder="0"/>
          </a-form-model-item>
        </a-col>

        <a-col>
          <a-form-model-item label="粉絲人數" prop="fansNum">
            <!-- <span>{{ form.nickname }}</span> -->
            <a-input v-model="form.fansNum" disabled placeholder="0"/>
          </a-form-model-item>
        </a-col>

        <a-col>
          <a-form-model-item label="米幣" prop="amount">
            <!-- <span>{{ form.nickname }}</span> -->
            <a-input v-model="form.amount" disabled placeholder="0"/>
          </a-form-model-item>
        </a-col>

        <a-col>
          <a-form-model-item label="收入金額(臺幣)" prop="incomeMoney">
            <!-- <span>{{ form.nickname }}</span> -->
            <a-input v-model="form.incomeMoney" disabled placeholder="0" />
          </a-form-model-item>
        </a-col>

      </a-row>


      <div class="bottom-control">
        <a-space>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/user/user'

export default {
  name: 'Details',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        mobile: null,

        password: null,

        avatar: null,

        nickname: null,

        sex: null,

        birthday: null,

        userSign: null,

        isNotice: null,

        status: 0,

        createTime: null,

        remark: null,

        email: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        mobile: null,
        password: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        isNotice: null,
        status: 0,
        createTime: null,
        remark: null,
        email: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>